import "core-js/modules/es.array.push.js";
import { getQrInfo } from '../api/article';
import { Toast } from 'vant';
export default {
  data() {
    return {
      value: '',
      showCalendar: false,
      popupvalue: '',
      columns: ['杭州', '宁波', '温州', '嘉兴', '湖州'],
      showPicker: false,
      actionshow: false,
      chosenContactId: '1',
      list: [{
        id: '1',
        name: '张三',
        tel: '13000000000',
        isDefault: true
      }, {
        id: '2',
        name: '李四',
        tel: '1310000000'
      }]
    };
  },
  methods: {
    homeroct() {
      this.$router.push('/about');
    },
    viewInformation() {
      this.$router.push('/viewInformation');
    },
    qrcode() {
      getQrInfo().then(res => {
        if (res.data.code == 101 || res.data.code == 102) {
          Toast.setDefaultOptions({
            duration: 3000
          });
          Toast({
            message: res.data.msg,
            className: "Toast-box"
          });
          this.$router.push('/viewInformation');
        } else {
          this.$router.push('/qrcode');
        }
      });
    }
  }
};
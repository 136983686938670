/*
 * @Author: vann 984797170@qq.com
 * @Date: 2023-02-06 13:19:40
 * @LastEditors: vann 984797170@qq.com
 * @LastEditTime: 2023-02-27 15:58:11
 * @FilePath: /visitor-weixin-app/src/utis/login.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import router from '../router'
import request from '../utis/request'
const baseurl = '//xxx'
// 解析url参数并获取code
function getUrlParam (name) { // name为要获取的参数名
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  var rrr = decodeURIComponent(window.location.search)
  var r = rrr.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}
// 请求微信服务器获取code 方法
function getUserCode () {
  // const redirectURL = encodeURI((baseurl + window.location.pathname + window.location.search).split('&code')[0]);//获取地址
  const targetUrl = 'https://wx.taotaoshe.cn/'
  const appid = 'wxd4b26a8d853b1ec7'
  const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${targetUrl}&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect`
  window.location.href = url
}

// 路由变化时
router.beforeEach((to, from, next) => {
  // 读取sessionStorage的user
  const user = JSON.parse(sessionStorage.getItem('user'))
  // 这里可以在开发生产环境下 加测试用户信息存储到sessionStorage
  if (document.domain == '127.0.0.1') { // 本地环境
    sessionStorage.setItem('user', JSON.stringify({
      uid: 'eyJhbGciOiJIUzI1NiJ9.eyJ0IjoiNjY1NDA6MTU1NzQ0ODE2MiJ9.Dt4QHKq58GOdI_j8GhK0o3SkyWwHDlfeHR0nh1nEgsw',
      identity: 1
    }))
    store.commit('updateUser', { uid: 'eyJhbGciOiJIUzI1NiJ9.eyJ0IjoiNjY1NDA6MTU1NzQ0ODE2MiJ9.Dt4QHKq58GOdI_j8GhK0o3SkyWwHDlfeHR0nh1nEgsw', user_id: 66540, identity: 1, avatar: 'http://chaofenxiang.vip/data/attachment/avatar/1904/28/ef0b7277767425348af3503e0759eacd.png', nickname: '小义', sex: '女', mobile: '15070026795' })// 赋值本地存储
  }

  // 已登录
  if (user) {
    next()// 释放路由 如果已经登录
  }

  // 未登录 且不是在测试环境下
  if (!user && document.domain != '127.0.0.1') {
    const code = getUrlParam('code')// 获取url 上面的code
    // 还未完成微信授权
    if (!code) { // 假如没code
      getUserCode()// 请求微信服务器获取code
    }
    // 已完成微信授权 假如已经获取到了code
    if (code) {
      // 使用code 去登录
      request({
        url: '/wx/callback?code=' + code,
        method: 'GET'
      }).then(res => {
        if (res.data) {
          const userInfo = res.data
          sessionStorage.setItem('user', JSON.stringify(userInfo))// 存sessionStorage<br>　　　　　　　　　　　　　　　　　　　　 next();
          window.location.href = '/'
        } else {

        }
      }).catch(err => {
        // 登录失败，请刷新重试
      })
    }
  } else {
    next()
  }
})

/*
 * @Author: vann 984797170@qq.com
 * @Date: 2022-12-14 11:43:49
 * @LastEditors: vann 984797170@qq.com
 * @LastEditTime: 2023-02-27 15:58:06
 * @FilePath: /visitor-weixin-app/src/utis/request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios'
const service = axios.create({
  baseURL: 'https://wx.taotaoshe.cn/data', // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(config => {
  return config
}, err => {
  console.log('请求拦截err: ' + err)
})
service.interceptors.response.use(res => {
  return res
}, err => {
  console.log('响应拦截err: ' + err.response.status)
  if (err.response.status == 408 && window.location.href.indexOf('login') < 0) {
    window.location.href = '#/login'
  }
})

// service.interceptors.response.use(

// )

export default service

import request from '../utis/request'

export function area (query) {
  return request({
    url: '/book/add',
    method: 'post',
    data: query
  })
}
export function areaUpload (query) {
  return request({
    url: '/book/update',
    method: 'post',
    data: query
  })
}
//校验手机号
export function phone (query) {
  return request({
    url: '/check/phone',
    method: 'post',
    data: query
  })
}
export function getUnit (query) {
  return request({
    url: '/area/getUnit',
    method: 'get',
    params: query
  })
}
export function bookget (query) {
  return request({
    url: '/book/get',
    method: 'get',
    params: query
  })
}
export function healthlist () {
  return request({
    url: '/health/list',
    method: 'get'
  })
}
export function personneladd (query) {
  return request({
    url: '/visitor/personnel/add',
    method: 'post',
    data: query
  })
}
export function personnelupdate (query) {
  return request({
    url: '/visitor/personnel/update',
    method: 'post',
    data: query
  })
}
export function personnelList (query) {
  return request({
    url: '/visitor/personnel/list',
    method: 'get',
    params: query
  })
}
export function removeById (query) {
  return request({
    url: '/visitor/personnel/removeById',
    method: 'post',
    data: query
  })
}
export function upload (query) {
  console.log(query, 'query')
  return request({
    url: '/attachment/upload',
    method: 'post',
    timeout:100000,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: query
  })
}
export function reasonslist () {
  return request({
    url: '/reasons/list',
    method: 'get'
  })
}
export function getGarden (query) {
  return request({
    url: '/area/getGarden',
    method: 'get',
    params: query
  })
}
export function bookList (query) {
  return request({
    url: '/book/list',
    method: 'get',
    params: query
  })
}
export function imgView (query) {
  return request({
    url: '/attachment/imgView',
    method: 'get',
    params: query,
    responseType: 'blob'
  })
}
export function bookUpdate (query) {
  return request({
    url: '/book/update',
    method: 'post',
    data: query
  })
}
// 列表更改状态
export function updateApprovalState (query) {
  return request({
    url: '/book/cancel',
    method: 'post',
    data: query
  })
}
export function qrCode (query) {
  return request({
    url: '/book/qrCode',
    method: 'get',
    params: query,
    responseType: 'blob'
  })
}
export function bookGet (query) {
  return request({
    url: '/book/get',
    method: 'get',
    params: query
  })
}
export function remove (query) {
  return request({
    url: '/book/remove',
    method: 'post',
    data: query
  })
}
export function getQrInfo (query) {
  return request({
    url: '/book/getQrInfo',
    method: 'get',
    params: query
  })
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "home"
  }, [_vm._m(0), _c('div', {
    staticClass: "home-ioc"
  }, [_c('div', [_c('div', {
    staticClass: "home-ioc-img",
    on: {
      "click": _vm.homeroct
    }
  }), _vm._m(1)]), _c('div', [_c('div', {
    staticClass: "home-ioc-img",
    on: {
      "click": _vm.viewInformation
    }
  }), _vm._m(2)]), _c('div', [_c('div', {
    staticClass: "home-ioc-img",
    on: {
      "click": _vm.qrcode
    }
  }), _vm._m(3)])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('img', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "src": require("../img/118.png"),
      "alt": ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "home-ioc-png"
  }, [_c('img', {
    staticStyle: {
      "width": "34px"
    },
    attrs: {
      "src": require("../img/122.png")
    }
  }), _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_vm._v("预约登记")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "home-ioc-png"
  }, [_c('img', {
    staticStyle: {
      "width": "34px"
    },
    attrs: {
      "src": require("../img/123.png")
    }
  }), _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_vm._v(" 查看记录")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "home-ioc-png"
  }, [_c('img', {
    staticStyle: {
      "width": "34px"
    },
    attrs: {
      "src": require("../img/124.png")
    }
  }), _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_vm._v("出示二维码")])]);
}];
export { render, staticRenderFns };
import request from './utis/request';
export default {
  data() {
    return {
      checkLogins: false,
      status: '0'
    };
  },
  created() {
    // this.checkLogin()
  },
  methods: {
    checkLogin() {
      const that = this;
      if (that.status > 3) return;
      request({
        url: '/wx/checkLogin',
        method: 'GET'
      }).then(res => {
        console.log('res', res);
        if ((res === null || res === void 0 ? void 0 : res.data.code) === 500) {
          localStorage.setItem('session', null);
          window.location.href = '/';
          that.status = that.status++;
        }
      });
    }
  }
};
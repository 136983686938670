(function () {
  function setRootFontSize () {
    console.log('111', 111)
    let rem, rootWidth
    const rootHtml = document.documentElement
    // 限制展现页面的最小宽度
    rootWidth = rootHtml.clientWidth < 750 ? 750 : rootHtml.clientWidth
    // 19.2 = 设计图尺寸宽 / 100（ 设计图的rem = 100 ）
    rem = rootWidth / 20
    // 动态写入样式
    rootHtml.style.fontSize = `${rem}px`
  }
  setRootFontSize()
  window.addEventListener('resize', setRootFontSize, false)
})()
